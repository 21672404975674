.MDH5S .WssAX .REqKD {
    height: 2rem;
    width: 100%;
    max-width: none;
    border-radius: 0.5rem;
    padding: 0px;
    font-weight: 700
}
    @media (min-width: 80em) {
    .MDH5S .jGgEn {
        position: relative;
        min-width: 152px
    }
}
